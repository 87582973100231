import React, { useState } from "react";
import { Drawer, IconButton } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import DrawerMenuList from "./DrawerMenuList";

export const DRAWER_WIDTH = 240;

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <DrawerMenuList />
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
