import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import PortraitIcon from "@mui/icons-material/Portrait";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

import { styled } from "@mui/material/styles";

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.secondary,
}));

const ListItemTextStyled = styled(ListItemText)(({ theme }) => ({
  whiteSpace: "normal",
}));

interface ListItemParams {
  to: string;
  text: string;
  icon: JSX.Element;
}

const listItems: ListItemParams[] = [
  {
    to: "/",
    text: "HOME",
    icon: <HomeIcon />,
  },
  {
    to: "/about",
    text: "ABOUT",
    icon: <PortraitIcon />,
  },
  {
    to: "/skills",
    text: "SKILLS",
    icon: <BuildOutlinedIcon />,
  },
  {
    to: "/projects",
    text: "PROJECTS",
    icon: <ColorLensOutlinedIcon />,
  },
  {
    to: "/contact",
    text: "CONTACT",
    icon: <MailOutlineOutlinedIcon />,
  },
  {
    to: "/summary",
    text: "SUMMARY",
    icon: <ArticleOutlinedIcon />,
  },
];

const DrawerMenuList = (): JSX.Element => {
  return (
    <List>
      {listItems.map((listItem) => (
        <LinkStyled key={listItem.text} to={listItem.to}>
          <ListItem button>
            <ListItemIcon>{listItem.icon}</ListItemIcon>
            <ListItemTextStyled primary={listItem.text} />
          </ListItem>
        </LinkStyled>
      ))}
    </List>
  );
};

export default DrawerMenuList;
