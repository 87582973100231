import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const delay = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const generatePDFDocument = async (
  filename: string,
  contentId: string,
  onStart: () => void,
  onDone: () => void
) => {
  await delay(200);

  const input = document.getElementById(contentId);
  if (!input) return;

  onStart();

  const canvas = await html2canvas(input);
  const imgData = canvas.toDataURL("image/png");
  const pdf = new jsPDF({
    unit: "pt", // points, pixels won't work properly
    format: [canvas.width, canvas.height], // set needed dimensions for any element
  });
  pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
  pdf.save(filename);
  onDone();
};
