import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import Home from "../pages/Home";
import About from "../pages/About";
import Skill from "../pages/Skill";
import Projects from "../pages/Projects";
import Contact from "../pages/Contact";
import Resume from "../pages/Resume";
import Summary from "../pages/Summary";
import {
  Tab,
  Tabs,
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DrawerComponent from "./DrawerComponent";
import { styled } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { ColorModeContext } from "../App";
import { navbarItems } from "../models/NavBar";

const TypographyStyled = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  cursor: "pointer",
}));

const Navbar = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const ToggleColorModeComponent = () => {
    return (
      <Tooltip title="Dark Mode">
        <IconButton
          sx={{ ml: 1 }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
        >
          {theme.palette.mode === "dark" ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Router>
      <Route
        path="/"
        render={(history) => (
          <AppBar color="default">
            <Toolbar>
              {isMobile ? (
                <>
                  <DrawerComponent />
                  <TypographyStyled variant="h5" pl={2}>
                    Jun's Portfolio
                  </TypographyStyled>
                  <ToggleColorModeComponent />
                  <div id="google_translate_element" />
                </>
              ) : (
                <>
                  <TypographyStyled variant="h5" pl={2}>
                    Jun's Portfolio
                  </TypographyStyled>

                  <Tabs value={history.location.pathname}>
                    {navbarItems.map((val) => {
                      return (
                        <Tab
                          label={val.label}
                          value={val.route}
                          key={val.label}
                          component={Link}
                          to={val.route}
                          sx={{
                            display:
                              val.label === "RESUME" ? "none" : "inline-flex",
                          }}
                        />
                      );
                    })}
                  </Tabs>
                  <ToggleColorModeComponent />
                  <div id="google_translate_element" />
                </>
              )}
            </Toolbar>
          </AppBar>
        )}
      />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/skills" component={Skill} />
        <Route path="/projects" component={Projects} />
        <Route path="/contact" component={Contact} />
        <Route path="/summary" component={Summary} />
        <Route path="/resume" component={Resume} />
      </Switch>
    </Router>
  );
};

export default Navbar;
