import React from "react";
import Footer from "../components/Footer";
import { Box, Container, Avatar, Typography, Grid } from "@mui/material";
import me from "../assets/images/jun1.webp";
import Typing from "react-typing-animation";

const Home = () => {
  return (
    <main>
      <Box
        sx={{
          pt: 16,
          pb: 6,
        }}
      >
        <Container maxWidth="md">
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="text.primary"
            gutterBottom
            sx={{
              fontSize: {
                xs: "1.5rem",
                sm: "2rem",
                md: "3rem",
              },
              fontWeight: "bold",
            }}
          >
            HELLO, I'm a
            <Typing>
              <Typing.Speed ms={200} />
              <span>FULL STACK DEVELOPER.</span>
            </Typing>
          </Typography>
          <Grid container spacing={4}>
            <Grid item>
              <Avatar
                alt="Junho Lee"
                src={me}
                sx={{
                  mr: 3,
                  mb: 3,
                  float: "left",
                  width: { xs: 100, sm: 150 },
                  height: { xs: 100, sm: 150 },
                }}
              />
              <Typography
                variant="h6"
                gutterBottom
                component="p"
                sx={{
                  lineHeight: 1.8,
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.3rem",
                    md: "1.5rem",
                  },
                }}
              >
                私はWEB,
                APP両方とも開発可能なフルスタックエンジニアです。業務経験は10年です。
                要件定義から開発、サービスオープンまで幅広く経験してきました。技術の幅も広くスピーディ重視の案件や小規模の案件ではRails、Pythonなどデータ処理や中規模以上の案件ではExpress、Reactなど案件によって技術を使い分けしています。
                クロスプラットフォーム開発に興味があって個人開発でReactNative、Flutterなどでアプリをリリースした経験もあります。
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </main>
  );
};

export default Home;
