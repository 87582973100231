import React from "react";
import {
  Box,
  Container,
  Fab,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Grid,
  Button,
} from "@mui/material";
import ResumeComponent from "../components/ResumeComponent";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { generatePDFDocument } from "../utils/util";

const Resume = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showDownloadButton, setDownloadButton] = React.useState(true);

  const handleGeneratePDFClick = (isopenDialog: boolean) => {
    setOpenDialog(isopenDialog);
  };

  return (
    <main>
      <Box
        sx={{
          pt: 16,
          pb: 6,
        }}
        id="content"
      >
        {showDownloadButton && (
          <Fab
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              display: { xs: "none", sm: "none", md: "inline-flex" },
            }}
            color="primary"
            aria-label="Download PDF"
            onClick={() => {
              generatePDFDocument(
                `JunhoLee_Resume_${window.location.hostname}.pdf`,
                "content",
                () => {
                  handleGeneratePDFClick(true);
                  setDownloadButton(false);
                },
                () => {
                  setDownloadButton(true);
                  handleGeneratePDFClick(false);
                }
              );
            }}
          >
            <SaveAltIcon />
          </Fab>
        )}
        <Container maxWidth="lg">
          <Grid container justifyContent="flex-end">
            {showDownloadButton && (
              <Button
                variant="contained"
                endIcon={<SaveAltIcon />}
                onClick={() => {
                  generatePDFDocument(
                    `JunhoLee_Resume_${window.location.hostname}.pdf`,
                    "content",
                    () => {
                      handleGeneratePDFClick(true);
                      setDownloadButton(false);
                    },
                    () => {
                      setDownloadButton(true);
                      handleGeneratePDFClick(false);
                    }
                  );
                }}
                sx={{ display: { xs: "none", sm: "none", md: "inline-flex" } }}
              >
                PDFでダウンロード
              </Button>
            )}
          </Grid>
        </Container>
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          <ResumeComponent />
        </Container>
      </Box>
      <SimpleDialog
        openDialog={openDialog}
        onClose={() => handleGeneratePDFClick(false)}
      />
    </main>
  );
};

export interface SimpleDialogProps {
  openDialog: boolean;
  onClose: () => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, openDialog } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={openDialog}>
      <DialogContent>
        <DialogContentText>
          PDFをダウンロードしています。
          <br />
          少々お待ちください。
        </DialogContentText>
        <Box
          sx={{ mt: 2 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Resume;
