import React from "react";
import {
  Typography,
  Link,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import hackersChamplooPresent from "../assets/images/etc/hackers-champloo-present.webp";
import cyberagentDeveloperBlog from "../assets/images/etc/cyberagent-developer-blog.webp";
import unityGraphicsProgramming from "../assets/images/etc/unity-graphics-programming.webp";

const Activity = () => {
  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardMedia
            component="img"
            image={hackersChamplooPresent}
            alt="hackers champloo present"
          />
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="p">
              ハッカソンチャンプル2018に登壇・発表
            </Typography>
            <Link
              href="https://hackers-champloo.org/2018/"
              target="_blank"
              style={{ wordWrap: "break-word" }}
            >
              https://hackers-champloo.org/2018/
            </Link>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardMedia
            component="img"
            image={cyberagentDeveloperBlog}
            alt="Cyberagent　Developer　Blog"
          />
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="p">
              技術研究について会社の技術ブログに投稿
            </Typography>
            <Link
              href="https://developers.cyberagent.co.jp/blog/archives/12666/"
              target="_blank"
              style={{ wordWrap: "break-word" }}
            >
              https://developers.cyberagent.co.jp/blog/archives/12666/
            </Link>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardMedia
            component="img"
            image={unityGraphicsProgramming}
            alt="Unity Graphics Programming"
          />
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="p">
              Unityの技術書籍の翻訳（日本語 → 韓国語）
            </Typography>
            <Link
              href="https://mattatz.org/works/unity-graphics-programming/"
              target="_blank"
              style={{ wordWrap: "break-word" }}
            >
              https://mattatz.org/works/unity-graphics-programming/
            </Link>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Activity;
