import React from "react";
import { Typography, Box, Container } from "@mui/material";
import Footer from "../components/Footer";
import ProjectCardList from "../components/ProjectCardList";

const Projects = () => {
  return (
    <Box sx={{ width: "100%", mt: 12 }}>
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Typography
            gutterBottom
            variant="h3"
            component="p"
            sx={{ fontWeight: "bold" }}
          >
            PERSONAL PROJECTS
          </Typography>
        </Box>
        <ProjectCardList />
        <Footer />
      </Container>
    </Box>
  );
};

export default Projects;
