import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  Chip,
} from "@mui/material";
import Activity from "../components/Activity";
import Footer from "../components/Footer";
import me from "../assets/images/jun2.webp";

const About = () => {
  return (
    <Box sx={{ width: "100%", mt: 12 }}>
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Typography
            gutterBottom
            variant="h3"
            component="p"
            sx={{ fontWeight: "bold" }}
          >
            ABOUT ME
          </Typography>
        </Box>
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          <Grid container spacing={4}>
            <Grid item>
              <Card
                sx={{
                  mr: 3,
                  mb: 3,
                  float: "left",
                  maxWidth: { xs: 100, sm: 150, md: 200 },
                  maxHeight: { xs: 100, sm: 150, md: 200 },
                }}
              >
                <CardMedia component="img" image={me} alt="Junho" />
              </Card>
              <Typography
                variant="h4"
                gutterBottom
                component="p"
                sx={{ fontWeight: "bold" }}
              >
                Hi. My name is Junho Lee
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                component="p"
                sx={{ lineHeight: 1.8, mb: 3 }}
              >
                私はFull Stack
                Developerです。IT業務経験は日本で2010年から現在まで継続しています。業務経験はバックエンドが多いですが、個人開発でアプリ開発経験も多数あります。韓国籍で日本語はビジネスレベルまで話せます。
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                component="p"
                sx={{ mb: 3, lineHeight: 2 }}
              >
                新しい技術が好きで色々学んでその知識を活かして新しいものを生み出すのが好きです。業務時間以外にも開発スキルを学んで幅広い知識を持っています。この知識を活かして会社ではスピーディ重視かつ小規模ならRailsやPythonで重いデータ処理や中規模以上ではNode.jsなど技術を使い分けしています。
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                component="p"
                sx={{ mb: 3, lineHeight: 2 }}
              >
                日本の公益のために何か貢献できないか？を考えて私の知識を活かして「新型コロナウイルス関連情報まとめサイト」を運営しています。日本の政府から提供しているAPIやExcelデータなどを読み込んでデータベース化して「PCR検査費用の比較」や「医療機関の運営状況をマップ上に可視化」など公益のためのサイトを運営しています。
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                component="p"
                sx={{ mb: 3, lineHeight: 2 }}
              >
                技術ブログも運営しています。パフォーマンスチューニングの話やオープンソースの開発・利用、地雷を踏んだ話などIT全般の知識共有が目的のサイトです。
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                component="p"
                sx={{ lineHeight: 2 }}
              >
                趣味はアプリ開発です。私の強みはバックエンドですが、Full-StackエンジニアになりたくてiOS,
                Android両方とも同時に開発ができる技術を主に勉強しました。はい、クロスプラットフォーム開発です。メインがFlutterですが、React
                Nativeは業務経験が多いです。1万人くらいの全社員が使えるAndroidタブレットとICカード(SUICA,
                PASMOなど)を連携して受付でICカードをかざすだけで出席ができるシステムを開発したりICカードから交通費精算システムを開発したこともあります。
                速さを求める案件に対してSwift言語を利用してiPadで社外の人向けの案内＆出入QRコード認証システムを開発したこともあります。
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          <Chip
            label="活動"
            variant="outlined"
            sx={{ fontWeight: "bold", mb: 2 }}
          />
          <Activity />
        </Container>
        <Footer />
      </Container>
    </Box>
  );
};

export default About;
