export type Align = "inherit" | "left" | "center" | "right" | "justify";

export type CardItem = {
  name: string;
  description: string;
  rating: number;
  iconName: string;
  align: Align;
};

export const skillCardItems: CardItem[] = [
  {
    name: "AWS",
    description:
      "EC2、ECS、S3、Lambdaなどを利用してバックエンド構築やAPIなど開発できます。",
    rating: 3.5,
    iconName: "amazonwebservices-original-wordmark.webp",
    align: "left",
  },
  {
    name: "Android",
    description:
      "業務経験でAndroidタブレットとICカードを利用してかざして参加者の出席状況を確認するアプリ開発",
    rating: 3.0,
    iconName: "android-plain-wordmark.webp",
    align: "left",
  },
  {
    name: "Apache",
    description:
      "社内開発でXAMPP開発経験3年。今は主にWordpress構築するときに利用するくらいです。",
    rating: 3,
    iconName: "apache-original-wordmark.webp",
    align: "left",
  },
  {
    name: "Babel",
    description:
      "ほとんどのReactプロジェクトでよく利用しています。このプロフィールサイトにも利用中",
    rating: 4,
    iconName: "babel-original.webp",
    align: "left",
  },
  {
    name: "Bootstrap",
    description: "社内向けのプロジェクトによく利用しています。",
    rating: 4.5,
    iconName: "bootstrap-original.webp",
    align: "left",
  },
  {
    name: "Circleci",
    description: "社内でJenkinとよく利用しています。利用に困らない程度",
    rating: 3.0,
    iconName: "circleci-plain-wordmark.webp",
    align: "left",
  },
  {
    name: "C#",
    description: "業務で2年くらいUnityでゲーム開発時によく利用しました。",
    rating: 3.5,
    iconName: "csharp-original.webp",
    align: "left",
  },
  {
    name: "Dart",
    description:
      "主にFlutter開発でよく使っています。業務経験はないが、勉強も兼ねて個人開発で2年間開発経験があります",
    rating: 4,
    iconName: "dart-original.webp",
    align: "left",
  },
  {
    name: "Django",
    description: "業務で1年くらい運用経験があります。",
    rating: 3.5,
    iconName: "django-original.webp",
    align: "left",
  },
  {
    name: "Docker",
    description:
      "業務経験も含む非常によく利用しています。Docker Composeもよく利用しています。",
    rating: 4.0,
    iconName: "docker-original-wordmark.webp",
    align: "left",
  },
  {
    name: "Express",
    description:
      "Node.jsを利用したWebアプリケーションフレームワークで必ず利用しています。",
    rating: 4.5,
    iconName: "express-original.webp",
    align: "left",
  },
  {
    name: "Firebase",
    description:
      "主に簡単なアプリ開発時に利用。Authentication, Firestore Database, Hosting, Crashlytics連携経験があります。",
    rating: 4.0,
    iconName: "firebase-plain-wordmark.webp",
    align: "left",
  },
  {
    name: "Flutter",
    description:
      "個人開発でよく利用しています。Google PlayやApp Storeなどアプリリリース経験とPlugin開発もあります。",
    rating: 4.5,
    iconName: "flutter-original.webp",
    align: "left",
  },
  {
    name: "Go",
    description:
      "社内でトイレ利用状況の可視化のRaspberry Piとセンサーを利用したIoT開発に使ったことあります。",
    rating: 3.5,
    iconName: "go-original.webp",
    align: "left",
  },
  {
    name: "Graphql",
    description:
      "社内システムで全社員がよく利用する受付予約システムを一人で開発して導入したことあります。クライアントから必要な項目だけ取得することもできて通信量削減にも繋がります。",
    rating: 4.0,
    iconName: "graphql-plain-wordmark.webp",
    align: "left",
  },
  {
    name: "Java",
    description:
      "業務経験も多数ありますが、最近はNode.jsをもっとよく使っています。",
    rating: 4.0,
    iconName: "java-original-wordmark.webp",
    align: "left",
  },
  {
    name: "Javascript",
    description:
      "ES6以上の構文をトランスパイラを利用してモダンな開発しています。",
    rating: 4.5,
    iconName: "javascript-original.webp",
    align: "left",
  },
  {
    name: "Jenkins",
    description:
      "社内でもっともよく使われているCI/CDです。本番構築や運用に困りません。",
    rating: 2.5,
    iconName: "jenkins-original.webp",
    align: "left",
  },
  {
    name: "Jquery",
    description:
      "業務経験でReactを利用する前にはよく使っていました。業務に問題なく導入できるレベルです。",
    rating: 5.0,
    iconName: "jquery-original-wordmark.webp",
    align: "left",
  },
  {
    name: "MongoDB",
    description:
      "個人・開発問わずよく利用しています。社内の場合は査定管理システムに利用しました。凍結したはずの要件が後になって覆ることがよくあるのでスキーマレス開発で効率よく開発と運用が可能です。トランザクションが必要な重要なサービス以外で利用中",
    rating: 4.0,
    iconName: "mongodb-original-wordmark.webp",
    align: "left",
  },
  {
    name: "MySQL",
    description:
      "昔からよく利用しています。トランザクションが必須、例えば会員入会時などにはMySQLを使い他の大量データを保存するだけならNoSQLを利用しています。",
    rating: 4.0,
    iconName: "mysql-original-wordmark.webp",
    align: "left",
  },
  {
    name: "Nginx",
    description:
      "個人・業務問わずほとんどのWebアプリ開発に利用しています。静的コンテンツ処理と並列処理性能がApacheより優れています。",
    rating: 4.0,
    iconName: "nginx-original.webp",
    align: "left",
  },
  {
    name: "Node.js",
    description:
      "小規模プロジェクトではよく利用しています。I/Oの非同期処理やC10K問題の対応など優れているのが特徴です。",
    rating: 4.5,
    iconName: "nodejs-original-wordmark.webp",
    align: "left",
  },
  {
    name: "PHP",
    description: "Wordpress開発時に利用しています。",
    rating: 3.5,
    iconName: "php-original.webp",
    align: "left",
  },
  {
    name: "Python",
    description: "AWS LamdaやUtil系の開発によく利用しています。",
    rating: 4.0,
    iconName: "python-original-wordmark.webp",
    align: "left",
  },
  {
    name: "Raspberry Pi",
    description:
      "社内のトイレ利用状況の可視化のプロジェクトでセンサーと通信するIoTデバイスで利用しました。ネットで検索しながらプロジェクトの作成可能レベルです。",
    rating: 3.0,
    iconName: "raspberrypi-original.webp",
    align: "left",
  },
  {
    name: "Rails",
    description:
      "小規模とスピード重視の案件によく使っています。1万人の全社員が使う受付APIを一人で開発した経験があります。",
    rating: 5.0,
    iconName: "rails-original-wordmark.webp",
    align: "left",
  },
  {
    name: "React",
    description:
      "業務と個人開発と問わずもっともよく使っています。このサイトもReactで作りました。",
    rating: 5,
    iconName: "react-original.webp",
    align: "left",
  },
  {
    name: "Redux",
    description:
      "基本的な知識はあります。小規模の開発では代わりにHookを使ってます。",
    rating: 3.0,
    iconName: "redux-original.webp",
    align: "left",
  },
  {
    name: "Ruby",
    description:
      "業務経験で5年くらいRuby on Railsを利用して小規模のサービスをよく作ってました。",
    rating: 5.0,
    iconName: "ruby-original-wordmark.webp",
    align: "left",
  },
  {
    name: "Swift",
    description: "会社ビル1FのiPad受付タブレットを一人で開発したことあります。",
    rating: 3.0,
    iconName: "swift-original.webp",
    align: "left",
  },
  {
    name: "Typescript",
    description: "Reactで開発するときは必ず利用しています。",
    rating: 5.0,
    iconName: "typescript-original.webp",
    align: "left",
  },
  {
    name: "Unity",
    description:
      "業務経験で2年くらい個人開発1年くらい経験したことあります。アプリもリリース済",
    rating: 3.5,
    iconName: "unity-original.webp",
    align: "left",
  },
  {
    name: "Vue.js",
    description:
      "勉強も兼ねて小規模の開発プロジェクトに導入したことあります。レファレンスを見ながら開発可能なレベルです。",
    rating: 3.5,
    iconName: "vuejs-original.webp",
    align: "left",
  },
  {
    name: "Webpack",
    description:
      "Reactプロジェクトで必ず利用しています。HTML生成、CSS圧縮、Javascript圧縮など様々なプラグインなど利用経験があります。",
    rating: 4.0,
    iconName: "webpack-original.webp",
    align: "left",
  },
  {
    name: "Wordpress",
    description:
      "業務経験がないが、副業でLTページや企業ページなど開発した経験があります。",
    rating: 3.5,
    iconName: "wordpress-original.webp",
    align: "left",
  },
];
