import React, { useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
  Tooltip,
  Modal,
  CardActionArea,
  Typography,
  IconButton,
  Stack,
  Divider,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import LinkIcon from "@mui/icons-material/Link";
import { projectsItems } from "../models/Projects";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  minWidth: "80%",
  transform: "translate(-50%, -50%)",
};

function importAll(r) {
  let images = {};
  r.keys().forEach((item, _) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}
const iconImages = importAll(
  require.context("../assets/images/icons", false, /\.(webp|png|jpe?g|svg)$/)
);

const portfolioImages = importAll(
  require.context(
    "../assets/images/portfolio",
    false,
    /\.(webp|png|jpe?g|svg)$/
  )
);

type ImageModalProps = {
  imageFile: string;
  alt: string;
};

const ProjectCardList: React.FC = () => {
  const [openImageModal, setImageModalOpen] = useState<boolean>(true);
  const [selectImage, setselectImage] = useState<ImageModalProps>();

  const handleImageModalOpen = (props: ImageModalProps) => {
    setselectImage(props);
    setImageModalOpen(true);
  };

  const renderImageModal = () => {
    const handleModalClose = () => setImageModalOpen(false);

    if (selectImage) {
      return (
        <Modal open={openImageModal} onClose={handleModalClose}>
          <Box sx={style}>
            <Card>
              <CardActionArea onClick={handleModalClose}>
                <CardMedia
                  component="img"
                  image={portfolioImages[selectImage.imageFile].default}
                  alt={selectImage.alt}
                />
              </CardActionArea>
            </Card>
          </Box>
        </Modal>
      );
    }
  };

  return (
    <>
      <Grid container justifyContent="center" spacing={4}>
        {projectsItems.map((value) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={value.id}>
              <Card key={value.title}>
                <CardMedia
                  component="img"
                  image={portfolioImages[value.image].default}
                  style={{ height: 180, paddingTop: "0" }}
                  alt={value.title}
                  onClick={() =>
                    handleImageModalOpen({
                      imageFile: value.image,
                      alt: value.title,
                    })
                  }
                />
                <CardContent className="content">
                  <Typography gutterBottom variant="h6" component="div">
                    {value.title}
                    <Tooltip title="全画面で見る">
                      <IconButton
                        onClick={() =>
                          handleImageModalOpen({
                            imageFile: value.image,
                            alt: value.title,
                          })
                        }
                      >
                        <ZoomInIcon />
                      </IconButton>
                    </Tooltip>
                    {value.link && (
                      <Tooltip title="リンクを開く">
                        <IconButton
                          onClick={() => window.open(value.link, "_blank")}
                        >
                          <LinkIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Typography>
                  <Divider />
                  <Box sx={{ mt: 1 }}>
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="body2" component="p">
                        {value.description}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {value.techIconNames.map((techIconName) => {
                          return (
                            <Card key={`_techIcon_${techIconName}`}>
                              <CardMedia
                                component="img"
                                image={iconImages[techIconName].default}
                                title={techIconName.split("-")[0]}
                                alt={techIconName.split("-")[0]}
                                sx={{ maxWidth: 40, maxHeight: 40 }}
                              />
                            </Card>
                          );
                        })}
                      </Stack>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {renderImageModal()}
    </>
  );
};

export default ProjectCardList;
