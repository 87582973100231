export type NavbarItem = {
  route: string;
  label: string;
};

export const navbarItems: NavbarItem[] = [
  {
    route: "/",
    label: "HOME",
  },
  {
    route: "/about",
    label: "ABOUT",
  },
  {
    route: "/skills",
    label: "SKILLS",
  },
  {
    route: "/projects",
    label: "PROJECTS",
  },
  {
    route: "/contact",
    label: "CONTACT",
  },
  {
    route: "/summary",
    label: "SUMMARY",
  },
  {
    route: "/resume",
    label: "RESUME",
  },
];
