import { v4 as uuid } from 'uuid';

export type ProjectItem = {
  id: string;
  title: string;
  description: string;
  image: string;
  techIconNames: string[];
  link: string;
};

export const projectsItems: ProjectItem[] = [
  {
    id: uuid(),
    title: 'Kguide',
    description:
      '韓国の観光・グルメ紹介アプリです。韓国政府の提供しているAPIで開発しました。',
    image: 'kguide.webp',
    techIconNames: [
      'flutter-original.webp',
      'dart-original.webp',
      'firebase-plain-wordmark.webp',
      'swift-original.webp',
      'kotlin-original.webp',
      'apple-original.webp',
      'android-plain-wordmark.webp',
    ],
    link: 'https://apps.apple.com/jp/app/kguide-korea-tour-guide-app/id1470239897',
  },
  {
    id: uuid(),
    title: 'ドキュメント変換サイト',
    description: 'OfficeやPDFなどドキュメントを相互変換するサイト',
    image: 'convert-cloud.com.webp',
    techIconNames: [
      'react-original.webp',
      'babel-original.webp',
      'express-original.webp',
      'javascript-original.webp',
      'typescript-original.webp',
      'nginx-original.webp',
      'nodejs-original-wordmark.webp',
      'docker-original-wordmark.webp',
      'webpack-original.webp',
    ],
    link: 'https://convert-cloud.com',
  },
  {
    id: uuid(),
    title: '全国医療機関の医療体制の状況',
    description:
      '政府CIOポータルの提供しているAPIを利用してマップを利用して医療機関の状況を見える化したサイトです。',
    image: 'medical-information-3.webp',
    techIconNames: [
      'react-original.webp',
      'babel-original.webp',
      'express-original.webp',
      'javascript-original.webp',
      'typescript-original.webp',
      'mongodb-original-wordmark.webp',
      'nginx-original.webp',
      'nodejs-original-wordmark.webp',
      'webpack-original.webp',
    ],
    link: 'https://medical-information.buddypia.com/covid19DailySurvey',
  },
  {
    id: uuid(),
    title: '自費検査を提供する検査機関一覧',
    description:
      '厚生労働省の提供しているExcelからデータを読み取ってデータベース化してバッチによる定期的な更新と最新情報を表示するサイトです。',
    image: 'medical-information-2.webp',
    techIconNames: [
      'react-original.webp',
      'babel-original.webp',
      'express-original.webp',
      'javascript-original.webp',
      'typescript-original.webp',
      'mongodb-original-wordmark.webp',
      'nginx-original.webp',
      'nodejs-original-wordmark.webp',
      'webpack-original.webp',
    ],
    link: 'https://medical-information.buddypia.com/pcrTestingCenters',
  },
  {
    id: uuid(),
    title: '新型コロナウイルス感染症対策サイトの最新情報',
    description:
      '新型コロナウイルス感染症対策(https://corona.go.jp/)からクローリングして取得したニュースを表示するサイトです。',
    image: 'medical-information-1.webp',
    techIconNames: [
      'react-original.webp',
      'babel-original.webp',
      'express-original.webp',
      'javascript-original.webp',
      'typescript-original.webp',
      'mongodb-original-wordmark.webp',
      'nginx-original.webp',
      'nodejs-original-wordmark.webp',
      'docker-original-wordmark.webp',
      'webpack-original.webp',
    ],
    link: 'https://medical-information.buddypia.com/news',
  },
  {
    id: uuid(),
    title: 'ポートフォリオ',
    description: 'ポートフォリオページです。',
    image: 'portfolio.webp',
    techIconNames: [
      'react-original.webp',
      'babel-original.webp',
      'javascript-original.webp',
      'typescript-original.webp',
      'nginx-original.webp',
      'nodejs-original-wordmark.webp',
      'docker-original-wordmark.webp',
      'webpack-original.webp',
    ],
    link: 'https://portfolio.buddypia.com',
  },
  {
    id: uuid(),
    title: '技術ブログ',
    description: '技術共有を目的としたブログを運営しています。',
    image: 'blog.webp',
    techIconNames: [
      'wordpress-original.webp',
      'javascript-original.webp',
      'apache-original-wordmark.webp',
      'bootstrap-original.webp',
      'docker-original-wordmark.webp',
      'php-original.webp',
    ],
    link: 'https://buddypia.com',
  },
  {
    id: uuid(),
    title: 'Jguide',
    description:
      '日本の観光・グルメ紹介アプリです。国土交通省観光庁の提供しているcsvからデータを抽出して作りました。',
    image: 'jguide.webp',
    techIconNames: [
      'flutter-original.webp',
      'dart-original.webp',
      'swift-original.webp',
      'kotlin-original.webp',
      'apple-original.webp',
      'android-plain-wordmark.webp',
      'firebase-plain-wordmark.webp',
    ],
    link: 'https://apps.apple.com/jp/app/jguide-%E6%97%85%E8%A1%8C-%E3%81%8A%E3%81%A7%E3%81%8B%E3%81%91%E3%82%AC%E3%82%A4%E3%83%89/id1491643755',
  },
  {
    id: uuid(),
    title: 'Maskda',
    description:
      '韓国の政府の提供している公的マスクの購入先をマップで現在位置から取得・在庫などを情報共有アプリです。（現在はストアから下ろしました。）',
    image: 'maskda.webp',
    techIconNames: [
      'flutter-original.webp',
      'dart-original.webp',
      'kotlin-original.webp',
      'android-plain-wordmark.webp',
      'firebase-plain-wordmark.webp',
    ],
    link: '',
  },
  {
    id: uuid(),
    title: 'Nimble Math',
    description: 'Unityを利用して開発しました。数学ゲームです。',
    image: 'nimble_math.webp',
    techIconNames: [
      'unity-original.webp',
      'csharp-original.webp',
      'swift-original.webp',
      'kotlin-original.webp',
      'apple-original.webp',
      'android-plain-wordmark.webp',
      'firebase-plain-wordmark.webp',
    ],
    link: 'https://apps.apple.com/no/app/nimble-math/id1441012564?l=ja',
  },
  {
    id: uuid(),
    title: 'Curve Ballz',
    description:
      'Unityを利用して開発しました。ボールを操作して目標に当てるゲームです。',
    image: 'curve_ballz.webp',
    techIconNames: [
      'unity-original.webp',
      'csharp-original.webp',
      'swift-original.webp',
      'kotlin-original.webp',
      'apple-original.webp',
      'android-plain-wordmark.webp',
      'firebase-plain-wordmark.webp',
    ],
    link: 'https://apps.apple.com/no/app/curve-ballz/id1439798556?l=ja',
  },
];
