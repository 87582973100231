import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {
  name: string;
};

const Copyright = (props: Props) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        {props.name}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default Copyright;
