import React from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ResumeComponent = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
        <Box sx={{ textAlign: 'center', mb: 5 }}>職務経歴書</Box>
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ mt: 10 }}>
        <Box sx={{ textAlign: 'right' }}>
          2021年12月3日現在
          <br />
          氏名：李　俊浩
        </Box>
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ fontWeight: 'bold', mt: 3 }}
      >
        ■職務要約
      </Typography>
      <Typography variant="body1" gutterBottom>
        株式会社 ABC Cooking Studio に入社後、約 3
        年間契約・予約システム開発に従事し、エンジニアとして要件定義にもとづいた設計やテスト、保守運用を担当。2013
        年 9 月頃株式会社ジークレストに転職後、20
        万ダウンロードのゲームのフルスタック（フロント・バックエンド）エンジニアとして要件定義からテスト、保守、運用を担当。2015
        年 3 月頃 株式会社サイバーエージェントに転職後、100
        万ダウンロードのゲームの
        UI＆運営ツールシステム開発を担当し、要件定義からテスト、保守、運用を担当。2016
        年 7
        月頃ハイブリットチームに移動。ハイブリット基盤システムの通知機能の開発担当。2016
        年 10 月全社システム本部に移動。社内 1
        万人が使うシステムを要件定義から設計・開発・テスト・運用まで一通り経験しました。
      </Typography>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2010年1月～2013年5月
              </TableCell>
              <TableCell component="th" scope="row">
                株式会社ABC Cooking Studio 情報システム部　入社
              </TableCell>
              <TableCell component="th" scope="row">
                プログラマー
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                2013年9月～2015年2月
              </TableCell>
              <TableCell component="th" scope="row">
                株式会社ジークレスト 第１ゲーム開発部　入社
              </TableCell>
              <TableCell component="th" scope="row">
                プログラマー
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                2015年3月～2016年10月
              </TableCell>
              <TableCell component="th" scope="row">
                株式会社サイバーエージェント　メディア事業部　入社
              </TableCell>
              <TableCell component="th" scope="row">
                プログラマー
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                2016年10月〜現在
              </TableCell>
              <TableCell component="th" scope="row">
                株式会社サイバーエージェント　全社システム本部　異動
              </TableCell>
              <TableCell component="th" scope="row">
                社内SE
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ fontWeight: 'bold', mt: 3 }}
      >
        ■活かせる経験・知識・技術
      </Typography>
      <Typography variant="body1" gutterBottom>
        ・社内SEとして本社・グループ会社を含む46社を対象する社内共通システムをスクラッチで要件定義からインフラ構築・設計・テスト・開発・運用まで一通り経験
        <br />
        ・小規模なら1~2ヶ月、中規模も3~5ヶ月の短いスパンでのスピード開発
        <br />
        ・一人でフロントエンド・バックエンド・アプリケーション開発経験を持つフルスタックエンジニアの知見
        <br />
        ・レガシーからモダンな環境まで幅広い技術スタック
        <br />
        ・本社・グループ会社を含む46社を対象する中・大規模の多数のAPI設計経験
        <br />
        ・サブリーダーとしての育成経験、プロジェクトマネジメント経験
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ fontWeight: 'bold', mt: 3 }}
      >
        ■職務経歴
      </Typography>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                2016年11月~現在　株式会社サイバーエージェント　全社システム本部
              </StyledTableCell>
              <StyledTableCell>雇用形態</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                事業内容: 業務システムや基幹システム開発
              </TableCell>
              <TableCell component="th" scope="row">
                正社員として勤務
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2016年11月~2017月3月
                <br />
                PC在庫管理システム
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                本社・グループ会社を含む46社の全社員が利用するPC配布・返却の管理システムの新規開発
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・ユーザーのPC配布・返却時の業務フローのシステム開発
                <br />
                ・サービスデスクのPC在庫管理のシステム開発
                <br />
                ・システムの全工程を一人で開発
                <br />
                【実績・取り組み】
                <br />
                ・ユーザーやサービスデスクの手続きにかかる時間を従来の15分
                →3分となる数分に短縮
                <br />
                ・提案して、前職での知見を活かして署名記録のライブラリ開発導入および記憶ベースのトラブルの改善
                <br />
                ・提案して、PC在庫を可視化することでPC移動流れの予測・余分にPC持ってる人の回収など新しい価値を生み出して貢献
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Ruby, JavaScript, CSS
                <br />
                【OS】
                <br />
                Amazon Linux 2
                <br />
                【DB】
                <br />
                MySQL
                <br />
                【フレームワーク】
                <br />
                Ruby on Rails, Bootstrap
              </TableCell>
              <TableCell component="th" scope="row">
                全2名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2017月4月~2017月8月
                <br />
                サービスデスクの予約・受付システム
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                本社・グループ会社を含む46社の全社員が利用するサービスデスクの予約システムの新規開発
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・サービスデスクの予約時にスマホ、デスクトップ、メールなどで順番のお知らせの通知システム開発
                <br />
                ・予約、受付拠点、通知手段のGraphQL API開発
                <br />
                ・サービスデスクが利用する受付管理の開発
                <br />
                ・ユーザー画面のフロントエンド以外、システムの全工程を一人で開発
                <br />
                【実績・取り組み】
                <br />
                ・ユーザーが混雑状況の確認と予約から順番通知で、従来の3分の1となる数分に短縮
                <br />
                ・部内のエンジニア育成時にこのシステムを利用
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Ruby, JavaScript, CSS
                <br />
                【OS】
                <br />
                Amazon Linux 2
                <br />
                【DB】
                <br />
                MySQL
                <br />
                【フレームワーク】
                <br />
                React, Node.js, Ruby on Rails, GraphQL, Bootstrap
              </TableCell>
              <TableCell component="th" scope="row">
                全4名
                <br />
                サブリーダー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2017年9月~2017月12月
                <br />
                退職者の秘密保持誓約書
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                本社の正社員・契約社員が退職時に紙で行っていたワークフローをシステムの新規開発
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・iPadで社員が入力した内容をPDF化して保存機能の開発
                <br />
                ・パソコン、スマホ、タブレットなどマルチプラットフォーム対応開発
                <br />
                【実績・取り組み】
                <br />
                ・署名記録のライブラリ開発導入
                <br />
                ・法的効力、改ざん検知機能の導入
                <br />
                ・手続きにかかる時間を従来の2分の1となる数分に短縮
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Ruby, JavaScript, CSS
                <br />
                【OS】
                <br />
                Amazon Linux 2
                <br />
                【DB】
                <br />
                MySQL
                <br />
                【フレームワーク】
                <br />
                Ruby on Rails, Bootstrap
              </TableCell>
              <TableCell component="th" scope="row">
                全2名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2018年1月~2018年4月
                <br />
                ICカードで社員出席チェックアプリ
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                本社・グループ会社を含む46社の全社員が利用するICカードをかざすことで「勉強会」「総会」「社外イベント」など社員出席チェックアプリ開発
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・ICカードから情報を読み出して社員データと照合することで社員判定する機能開発
                <br />
                【実績・取り組み】
                <br />
                ・子会社で開発できなかった案件を個人でアプリ開発を活かして要件通りに開発成功し評価と表彰
                <br />
                ・バックグランドでほぼリアルタイムで出席した社員データを同期して無人運用もできるように開発
                <br />
                ・機能拡張して「勉強会」「総会」「社外イベント」「社内のコロナワクチン予防接種」など幅広く多目的に利用中
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Android, Java, JavaScript
                <br />
                【DB】
                <br />
                Realtime Database
                <br />
                【フレームワーク】
                <br />
                React Native
              </TableCell>
              <TableCell component="th" scope="row">
                全5名
                <br />
                リーダー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2018年5月~2018年6月
                <br />
                トイレ利用情報可視化システム
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                トイレのドアの出入りの情報を分析・長時間利用の防止プロジェクト
                <br />
                【担当フェーズ】
                <br />
                基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・Raspberry Piをサーバにして接触センサーを利用してIoT開発
                <br />
                【実績・取り組み】
                <br />
                ・時系列データベースを管理ツールで可視化して、人事労務に提供することで長時間居座の対応
                <br />
                ・複数の社内システムやアプリと連携してトイレ空き状況を提供し、トイレ不足の問題改善
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Python, Go
                <br />
                【DB】
                <br />
                InfluxDB
                <br />
                【フレームワーク】
                <br />
                Gin, Grafana
              </TableCell>
              <TableCell component="th" scope="row">
                全3名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2018年6月~2018年10月
                <br />
                社内公式アプリ開発
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                空きスペース可視化、社員証、サービスデスクのマップ表示、通知など多目的の社員専用のアプリ開発
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・監視カメラの映像を利用してほぼリアルタイムで空きスペースの混雑状況を判定する機能開発
                <br />
                【実績・取り組み】
                <br />
                ・混雑状況を可視化することで社員の無駄な移動コスト削減
                <br />
                ・CyberAgentのDeveloperブログに研究結果の公開
                <br />
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                JavaScript, Java, Ruby
                <br />
                【OS】
                <br />
                Amazon Linux 2
                <br />
                【DB】
                <br />
                RDMS
                <br />
                【フレームワーク】
                <br />
                React, React Native, Java, Swift, Webpack, Ruby on Rails
              </TableCell>
              <TableCell component="th" scope="row">
                全6名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2018年11月~2019年2月
                <br />
                来客受付システム開発
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                東京渋谷本社・グループ会社の来客受付システム開発・運用
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・iPadでQRコードを利用して来客アプリ開発
                <br />
                ・APIを通じて本社・グループ会社を含む連絡先情報を表示する機能開発
                <br />
                【実績・取り組み】
                <br />
                ・積極的に手を挙げて、個人でアプリ開発を活かして要件通りに開発成功し評価と表彰
                <br />
                ・無人運用できるように徹底的に検証してメモリリークや落ちることなく運用中
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Swift
                <br />
                【デザインパターン】
                <br />
                MVVMパターン
              </TableCell>
              <TableCell component="th" scope="row">
                全5名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2019年4月~2019年7月
                <br />
                内定通知書プロジェクト
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                郵送と紙で行っていた内定通知書をペーパーレスのシステム化
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・iPadで社員が入力した内容をPDF化して保存機能の開発
                <br />
                ・パソコン、スマホ、タブレットなどマルチプラットフォーム対応開発
                <br />
                【実績・取り組み】
                <br />
                ・新卒採用チームの郵送や紙の確認作業を大幅削減（従来の3分の1に短縮）
                <br />
                ・提案して、リストアップされたデータと突き合わせて誤送信や作業漏れなどを防止
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Ruby, JavaScript, CSS
                <br />
                【OS】
                <br />
                Amazon Linux 2
                <br />
                【DB】
                <br />
                MySQL
                <br />
                【フレームワーク】
                <br />
                Ruby on Rails, Bootstrap
              </TableCell>
              <TableCell component="th" scope="row">
                全2名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2019年8月~2019年10月
                <br />
                組織に紐づくグループメーリングリスト作成システム
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                GSuiteとAPI連携して社内向けのメーリングリストの管理システムの開発
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・組織データと社員データの関連付けからGSuite連携
                <br />
                ・Google Admin APIを用いてグループML作成・修正・削除機能の開発
                <br />
                【実績・取り組み】
                <br />
                ・退職、離職、異動など自動でメーリングリストを自動管理する事でセキュリティ向上
                <br />
                ・メーリングリスト作成時間5分 →
                10秒、メーリングリストのメンバー更新時間10分 →
                0秒に業務フロー改善
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Rails, JavaScript, CSS
                <br />
                【OS】
                <br />
                CentOS
                <br />
                【DB】
                <br />
                MySQL
                <br />
                【フレームワーク】
                <br />
                Ruby on Rails, Bootstrap
              </TableCell>
              <TableCell component="th" scope="row">
                全2名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2019年11月~2020年1月
                <br />
                インターンシップの秘密保持誓約書プロジェクト
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                郵送と紙で行っていた秘密保持誓約書をペーパーレスのシステム化
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・要件をヒアリングして、インターンシップの紙で行った作業をペーパーレス化のシステム開発
                <br />
                ・スマホからどこでも期間内にインターンシップの締結ができるように開発
                <br />
                【実績・取り組み】
                <br />
                ・提案して、1:1対面で行っていた手続きフローをQRコードを利用することで新卒採用チームの人員と作業コストの削減（およそ60分程削減）
                <br />
                ・インターン生が利用するため、より丁寧に入力項目の説明とバリデーションを徹底的にチェックしエラーに関する問い合わせを0件に達成
                <br />
                ・可視化による不参加のインターンシップのチェック作業が1時間以上かかった作業が数分に工数削減
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Rails, JavaScript, CSS
                <br />
                【OS】
                <br />
                CentOS
                <br />
                【DB】
                <br />
                MySQL
                <br />
                【フレームワーク】
                <br />
                Ruby on Rails, Bootstrap
              </TableCell>
              <TableCell component="th" scope="row">
                全2名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2020年2月~2020年3月
                <br />
                有期入社秘密保持誓約書
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                雇用形態が派遣や業務委託など雇用期間が決まった人向けの秘密保持誓約書プロジェクト
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・郵送と紙で行っていた有期入社秘密保持誓約書をペーパーレスのシステム化
                <br />
                ・スマホからどこでも期間内に有期入社の秘密保持誓約書の締結ができるように開発
                <br />
                【実績・取り組み】
                <br />
                ・既存ペーパーレスシステムの横展開することで低コストで機能実現
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Rails, JavaScript, CSS
                <br />
                【OS】
                <br />
                CentOS
                <br />
                【DB】
                <br />
                MySQL
                <br />
                【フレームワーク】
                <br />
                Ruby on Rails, Bootstrap
              </TableCell>
              <TableCell component="th" scope="row">
                全2名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2019月4月~現在
                <br />
                査定管理システム
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                人事労務の査定をExcelで行っていたをのセキュリティ向上とExcel作業による業務ミスの改善するプロジェクト
                <br />
                【担当フェーズ】
                <br />
                要件定義、インフラ構築、基本設計、詳細設計、テスト、運用保守
                <br />
                【業務内容】
                <br />
                ・Excelで行っていた年俸計算、評価、交通費計算など約40項目をシステム化
                <br />
                ・作業フローの整理と開発スコープの定義
                <br />
                ・書き込み、閲覧、フィードバックなどしっかり権限管理設計
                <br />
                【実績・取り組み】
                <br />
                ・本社給与査定管理者のグループ会社や役員のフィードバック、ダブルチェック、チャットやメールなどあらゆるツールからくるExcelの整合性のチェックなどチェック作業地獄のコスト削減（試験運用中ですが、1.5ヶ月にわたる作業
                → 0.5ヶ月に改善）
                <br />
                ・人事労務10名のチェック作業やバリデーションチェックなどのあらゆる作業のコスト削減（試験運用中ですが、1ヶ月にわたる作業が約0.7ヶ月に改善）
                <br />
                ・あらゆる手段（チャット、メール、メッセンジャーなど）でExcel受け渡しを廃止し、受け渡し経路を統一することでセキュリティー向上とデータの整合性向上
                <br />
                ・大量なデータを捌く、レプリカセット、スケーラビリティを考えてMongoDBを導入（単純速度比較で既存より3倍以上早い）
              </TableCell>
              <TableCell component="th" scope="row">
                【言語】
                <br />
                Ruby, Java, JavaScript, TypeScript, CSS
                <br />
                【OS】
                <br />
                Amazon Linux 2
                <br />
                【DB】
                <br />
                MySQL, MongoDB
                <br />
                【フレームワーク】
                <br />
                Ruby on Rails, Bootstrap, Apache POI, Vue.js, Webpack
              </TableCell>
              <TableCell component="th" scope="row">
                全5名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                2016年7月~2016年10月　株式会社サイバーエージェント
                ハイブリットチーム
              </StyledTableCell>
              <StyledTableCell>雇用形態</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                事業内容: メディア事業部のアプリを支える基幹システム開発
              </TableCell>
              <TableCell component="th" scope="row">
                正社員として勤務
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2016年7月~2016年10月
                <br />
                基幹システム開発
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                メディア事業部のアプリを支える基幹システム開発
                <br />
                【担当フェーズ】
                <br />
                基本設計、詳細設計、テスト、運用保守
                <br />
              </TableCell>
              <TableCell component="th" scope="row">
                Java, Scala, Amazon Linux 2, MySQL, Spring Framework
              </TableCell>
              <TableCell component="th" scope="row">
                全6名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                2015年3月～2016年6月　株式会社サイバーエージェント　オルタナティブガールズチーム
              </StyledTableCell>
              <StyledTableCell>雇用形態</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                事業内容: Unityでカードバトルアプリの開発
              </TableCell>
              <TableCell component="th" scope="row">
                正社員として勤務
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2016年7月~2014年10月
                <br />
                オルタナティブガールズ
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                VR対応 美少女 RPGゲームの新規開発・運用
                <br />
                【担当フェーズ】
                <br />
                基本設計、詳細設計、テスト、運用保守
                <br />
                【実績・取り組み】
                <br />
                ・自作したオープンソースをプロジェクトに導入
                <br />
                ・アプリ50万ダウンロード
              </TableCell>
              <TableCell component="th" scope="row">
                Unity, C#, Objective-C, Java
              </TableCell>
              <TableCell component="th" scope="row">
                全20名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 10 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                2013年9月～2015年2月　株式会社ジークレスト 第１ゲーム開発部
              </StyledTableCell>
              <StyledTableCell>雇用形態</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                事業内容: ゲームのフルスタック開発（バックエンド・アプリ）
              </TableCell>
              <TableCell component="th" scope="row">
                正社員として勤務
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2013年9月~2014年10月
                <br />
                ドラゴンズシャドウ
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                ダークファンタジー系ロールプレイングカードゲームの新規開発・運用
                <br />
                【担当フェーズ】
                <br />
                基本設計、詳細設計、テスト、運用保守
                <br />
                【実績・取り組み】
                <br />
                ・最も重要な課金要素であるスキル開発を一人で巻き取って提案から開発・運用
              </TableCell>
              <TableCell component="th" scope="row">
                Java, JavaScript, CSS, Objective-C, Red Hat, MySQL, Seasar2,
                Backbone.js, SCSS
                <br />
              </TableCell>
              <TableCell component="th" scope="row">
                全20名
                <br />
                メンバー、開発サブリーダ
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2014年11月~2015月2月
                <br />
                夢王国と眠れる100人の王子様
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                女性向けのパズルゲームの新規開発
                <br />
                【担当フェーズ】
                <br />
                要件定義、基本設計、詳細設計、テスト、運用保守
                <br />
                【実績・取り組み】
                <br />
                ・アプリ100万ダウンロード
              </TableCell>
              <TableCell component="th" scope="row">
                C++,Java, Objective-C, Red Hat, MySQL, Seasar2, Backbone.js,
                SCSS
              </TableCell>
              <TableCell component="th" scope="row">
                全20名
                <br />
                メンバー、開発サブリーダ
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 10 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                2010年1月～2013年3月　株式会社ABC Cooking Studio 情報システム部
              </StyledTableCell>
              <StyledTableCell>雇用形態</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                事業内容: ソフトウェアシステム開発
              </TableCell>
              <TableCell component="th" scope="row">
                契約社員として勤務
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer sx={{ mt: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>期間</StyledTableCell>
              <StyledTableCell>プロジェクト名及び業務内容</StyledTableCell>
              <StyledTableCell>開発環境</StyledTableCell>
              <StyledTableCell>役割/担当/規模</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2010年1月~2013年5月
                <br />
                基幹システム開発
              </TableCell>
              <TableCell component="th" scope="row">
                【プロジェクト概要】
                <br />
                契約、予約、顧客などを一元管理する営業支援システムの開発
                <br />
                【担当フェーズ】
                <br />
                詳細設計、テスト、運用保守
                <br />
                【実績・取り組み】
                <br />
                ・ITpro EXPO 2012エンタープライズモバイルAWARD 2012の受賞
                <br />
                ・PHP関連のオープンソースを二つGithubに公開
              </TableCell>
              <TableCell component="th" scope="row">
                PHP, CentOS, Apache, Oracle, CodeIgniter, Red Hat, Oracle,
                MySQL, MSSQL, CodeIgniter, PostgreSQL
              </TableCell>
              <TableCell component="th" scope="row">
                全4名
                <br />
                メンバー
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ fontWeight: 'bold', mt: 3 }}
      >
        ■テクニカルスキル
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                プログラミング言語
              </TableCell>
              <TableCell component="th" scope="row">
                TypeScript, JavaScript, Ruby, Python, Dart, C#, Java, PHP,
                Swift, Go Kotlin
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                フレームワーク
              </TableCell>
              <TableCell component="th" scope="row">
                Ruby on Rails, Express, React, React Native, Flutter, Vue.js,
                Unity, Wordpress
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                インフラ
              </TableCell>
              <TableCell component="th" scope="row">
                AWS, Docker
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                DB
              </TableCell>
              <TableCell component="th" scope="row">
                MySQL, MongoDB
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                その他
              </TableCell>
              <TableCell component="th" scope="row">
                Webpack, Babel, Jenkins, CircleCI, Firebase, Graphql, Raspberry
                Pi
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ fontWeight: 'bold', mt: 3 }}
      >
        ■資格（下記は全て韓国で取った資格です。日本の資格に相応する翻訳済）
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                2003年7月
              </TableCell>
              <TableCell component="th" scope="row">
                ITパスポート
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                2003年9月
              </TableCell>
              <TableCell component="th" scope="row">
                Word文書処理技術認定試験
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                2003年10月
              </TableCell>
              <TableCell component="th" scope="row">
                パソコン検定
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                2009年6月
              </TableCell>
              <TableCell component="th" scope="row">
                応用情報 技術者試験
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                2009年6月
              </TableCell>
              <TableCell component="th" scope="row">
                Ubiquaitous Knowledge Certification
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ fontWeight: 'bold', mt: 3 }}
      >
        ■その他
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Github
              </TableCell>
              <TableCell component="th" scope="row">
                https://github.com/shoridevel
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                厚生労働省の「自費検査を提供する検査機関一覧」Excelを見やすく改善やAPI化したサービス
              </TableCell>
              <TableCell component="th" scope="row">
                https://medical-information.buddypia.com/pcrTestingCenters
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                全国医療機関の医療体制の状況のAPIを利用してマップ上で可視化サービス
              </TableCell>
              <TableCell component="th" scope="row">
                https://medical-information.buddypia.com/covid19DailySurvey
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                韓国の観光情報のまとめアプリ
              </TableCell>
              <TableCell component="th" scope="row">
                <p>
                  AppStore: https://apps.apple.com/us/app/id1470239897?l=ja
                  <br />
                  Google Play:
                  https://play.google.com/store/apps/details?id=com.buddypia.kguide&hl=ja
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                画像やPDFなどを他のファイルフォーマットに変換するサービス（APIも提供）
              </TableCell>
              <TableCell component="th" scope="row">
                http://convert-cloud.com/
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResumeComponent;
