import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import DescriptionIcon from "@mui/icons-material/Description";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Copyright from "./Copyright";
import { IconButton, Stack, Tooltip } from "@mui/material";

function Footer() {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ pt: 5, pb: 2 }}
    >
      <Copyright name="Buddypia" />
      <Tooltip title="GitHub">
        <IconButton
          color="inherit"
          aria-label="GitHub"
          onClick={() => window.open("https://github.com/shoridevel", "_blank")}
        >
          <GitHubIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="LinkedIn">
        <IconButton
          color="inherit"
          aria-label="LinkedIn"
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/junho-lee-6b4a6695/",
              "_blank"
            )
          }
        >
          <LinkedInIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Blog">
        <IconButton
          color="inherit"
          aria-label="Blog"
          onClick={() => window.open("https://buddypia.com", "_blank")}
        >
          <DescriptionIcon />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

export default Footer;
