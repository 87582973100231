import React from "react";
import Footer from "../components/Footer";
import {
  Box,
  Container,
  Stack,
  Avatar,
  Typography,
  Fab,
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Link,
  Chip,
  Grid,
  Button,
} from "@mui/material";
import me from "../assets/images/jun1.webp";
import SkillCardList from "../components/SkillCardList";
import ProjectCardList from "../components/ProjectCardList";
import Activity from "../components/Activity";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { generatePDFDocument } from "../utils/util";

const selfIntroduction = {
  fontSize: {
    xs: "0.8rem",
    sm: "0.8rem",
    md: "0.1rem",
  },
};

const Summary = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showDownloadButton, setDownloadButton] = React.useState(true);

  const handleGeneratePDFClick = (isopenDialog: boolean) => {
    setOpenDialog(isopenDialog);
  };

  return (
    <main>
      <Box
        sx={{
          pt: 16,
          pb: 6,
        }}
        id="content"
      >
        {showDownloadButton && (
          <Fab
            sx={{
              position: "fixed",
              bottom: 16,
              right: 16,
              display: { xs: "none", sm: "none", md: "inline-flex" },
            }}
            color="primary"
            aria-label="Download PDF"
            onClick={() => {
              generatePDFDocument(
                `JunhoLee_Resume_${window.location.hostname}.pdf`,
                "content",
                () => {
                  handleGeneratePDFClick(true);
                  setDownloadButton(false);
                },
                () => {
                  setDownloadButton(true);
                  handleGeneratePDFClick(false);
                }
              );
            }}
          >
            <SaveAltIcon />
          </Fab>
        )}
        <Container maxWidth="lg">
          <Grid container justifyContent="flex-end">
            {showDownloadButton && (
              <Button
                variant="contained"
                endIcon={<SaveAltIcon />}
                onClick={() => {
                  generatePDFDocument(
                    `JunhoLee_Resume_${window.location.hostname}.pdf`,
                    "content",
                    () => {
                      handleGeneratePDFClick(true);
                      setDownloadButton(false);
                    },
                    () => {
                      setDownloadButton(true);
                      handleGeneratePDFClick(false);
                    }
                  );
                }}
                sx={{ display: { xs: "none", sm: "none", md: "inline-flex" } }}
              >
                PDFでダウンロード
              </Button>
            )}
          </Grid>
        </Container>
        <Container maxWidth="lg" sx={{ width: "fit-content", mb: 5 }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              alt="Junho Lee"
              src={me}
              sx={{
                width: {
                  xs: 60,
                  sm: 100,
                  md: 150,
                },
                height: {
                  xs: 60,
                  sm: 100,
                  md: 150,
                },
              }}
            />
            <TableContainer>
              <Table
                size="small"
                style={{
                  width: "auto",
                  tableLayout: "auto",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ selfIntroduction }}>名前</TableCell>
                    <TableCell sx={{ selfIntroduction }}>
                      李　俊浩(Junho Lee)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ selfIntroduction }}>職種</TableCell>
                    <TableCell sx={{ selfIntroduction }}>
                      システムエンジニア
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ selfIntroduction }}>ブログ</TableCell>
                    <TableCell sx={{ selfIntroduction }}>
                      <Link href="https://buddypia.com" target="_blank">
                        https://buddypia.com
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ selfIntroduction }}>Github</TableCell>
                    <TableCell sx={{ selfIntroduction }}>
                      <Link
                        href="https://github.com/shoridevel"
                        target="_blank"
                      >
                        https://github.com/shoridevel
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Stack>
        </Container>
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          <Chip
            label="自己紹介"
            variant="outlined"
            sx={{ fontWeight: "bold", mb: 2 }}
          />
          <Typography
            variant="subtitle1"
            align="left"
            color="text.secondary"
            paragraph
          >
            私はWEB,
            APP両方とも開発可能なフルスタックエンジニアです。業務経験は10年です。
            要件定義から開発、サービスオープンまで幅広く経験してきました。技術の幅も広くスピーディ重視の案件や小規模の案件ではRails、Pythonなどデータ処理や中規模以上の案件ではExpress、Reactなど案件によって技術を使い分けしています。
            クロスプラットフォーム開発に興味があって個人開発でReactNative、Flutterなどでアプリをリリースした経験もあります。
            国籍は韓国です。日本語はビジネス上級レベルです。
          </Typography>
        </Container>
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          <Chip
            label="個人プロジェクト"
            variant="outlined"
            sx={{ fontWeight: "bold", mb: 2 }}
          />
          <ProjectCardList />
        </Container>
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          <Chip
            label="スキル"
            variant="outlined"
            sx={{ fontWeight: "bold", mb: 2 }}
          />
          <SkillCardList />
        </Container>
        <Container maxWidth="lg" sx={{ mb: 5 }}>
          <Chip
            label="活動"
            variant="outlined"
            sx={{ fontWeight: "bold", mb: 2 }}
          />
          <Activity />
        </Container>
      </Box>
      <Footer />
      <SimpleDialog
        openDialog={openDialog}
        onClose={() => handleGeneratePDFClick(false)}
      />
    </main>
  );
};

export interface SimpleDialogProps {
  openDialog: boolean;
  onClose: () => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, openDialog } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={openDialog}>
      <DialogContent>
        <DialogContentText>
          PDFをダウンロードしています。
          <br />
          少々お待ちください。
        </DialogContentText>
        <Box
          sx={{ mt: 2 }}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Summary;
