import React from "react";
import { TextField, Box, Container, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import Footer from "../components/Footer";
import * as emailjs from "emailjs-com";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

const Contact = () => {
  const { register, handleSubmit, errors } = useForm();
  const [submitLoading, setSubmitLoading] = React.useState(false);

  emailjs.init(`${process.env.REACT_APP_USER_ID}`);

  const sendMail = (data) => {
    setSubmitLoading(true);

    if (!data || Object.keys(data).length === 0) {
      console.log("Form parameters are required.");
      setSubmitLoading(false);
      return;
    }
    emailjs
      .send(
        `${process.env.REACT_APP_SERVICE_ID}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,
        data
      )
      .then(
        (response) => {
          console.log("Success!!!", response.status, response.text);
          setSubmitLoading(false);
        },
        (error) => {
          console.log("Failed...", error);
          setSubmitLoading(false);
        }
      );
  };

  return (
    <Box sx={{ width: "100%", mt: 12 }}>
      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Typography
            gutterBottom
            variant="h3"
            component="p"
            sx={{ fontWeight: "bold" }}
          >
            CONTACT
          </Typography>
        </Box>

        <Typography gutterBottom variant="subtitle1" component="p">
          お気軽に下記フォームよりご連絡ください。下記フォーム内容はいずれも必須事項となっております。必須事項をご記入の上ご連絡ください。
        </Typography>
        <form className="forms" onSubmit={handleSubmit(sendMail)}>
          <TextField
            className="formsItem"
            variant="filled"
            label="氏名(必須)"
            type="text"
            name="name"
            fullWidth
            margin="normal"
            inputRef={register({ required: true, maxLength: 20 })}
            error={Boolean(errors.name)}
            helperText={errors.name && "氏名をご入力ください"}
          />
          <TextField
            className="formsItem"
            variant="filled"
            label="メールアドレス(必須)"
            type="email"
            name="email"
            fullWidth
            margin="normal"
            inputRef={register({ required: true })}
            error={Boolean(errors.email)}
            helperText={errors.email && "メールアドレスをご入力ください"}
          />
          <TextField
            className="formsItem"
            variant="filled"
            label="件名(必須)"
            type="text"
            name="subject"
            fullWidth
            margin="normal"
            inputRef={register({ required: true })}
            error={Boolean(errors.subject)}
            helperText={errors.subject && "件名をご入力ください"}
          />
          <TextField
            className="formsItem"
            variant="filled"
            label="お問い合わせ内容(必須)"
            type="text"
            name="body"
            fullWidth
            margin="normal"
            inputRef={register({ required: true })}
            error={Boolean(errors.body)}
            helperText={errors.body && "お問い合わせをご入力して下さい。"}
            multiline
            rows="8"
          />
          <LoadingButton
            className="submitButton"
            variant="contained"
            color="primary"
            type="submit"
            loading={submitLoading}
            loadingPosition="end"
            endIcon={<SendIcon />}
            style={{ marginTop: 10 }}
          >
            送信
          </LoadingButton>
        </form>
        <Footer />
      </Container>
    </Box>
  );
};

export default Contact;
